import { useMutation, useQuery } from '@apollo/client';
import {
  HStack,
  SkeletonCircle,
  SkeletonText,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { UserAvatar } from '@outdoorly/avatar';
import { Button } from '@outdoorly/button';
import { Card } from '@outdoorly/card';
import { Field, TextInput } from '@outdoorly/input';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../globals';
import { Heading } from './heading';
import { updateEmail, sendResetPasswordEmail } from '../../providers/auth';

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      firstName
      lastName
      email
      avatar {
        url
      }
    }
  }
`;

export const UPDATE_NAME_MUTATION = gql`
  mutation UpdateNameMutation($firstName: String, $lastName: String) {
    accountUpdate(input: { firstName: $firstName, lastName: $lastName }) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_AVATAR_MUTATION = gql`
  mutation UpdateAvatarMutation($image: Upload!) {
    userAvatarUpdate(image: $image) {
      user {
        id
        avatar {
          url
        }
      }
    }
  }
`;

const DELETE_AVATAR_MUTATION = gql`
  mutation DeleteAvatarMutation {
    userAvatarDelete {
      user {
        id
        avatar {
          url
        }
      }
    }
  }
`;

export type AccountProps = {
  isLoading?: boolean;
  user?: {
    avatar?: {
      url?: string
    },
    email?: string;
    firstName?: string;
    lastName?: string;
  }
}

const Account: React.FC<AccountProps> = ({ isLoading = true, user }) => {
  const [updateNameMutation, { loading }] = useMutation(UPDATE_NAME_MUTATION);
  const [updateAvatarMutation] = useMutation(UPDATE_AVATAR_MUTATION);
  const [deleteAvatarMutation] = useMutation(DELETE_AVATAR_MUTATION);

  const inputFile = useRef(null);

  const onSubmit = useCallback(
    ({ firstName, lastName }) =>
      updateNameMutation({ variables: { firstName, lastName } }),
    [updateNameMutation]
  );

  const onChangeAvatar = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const file = event.target.files[0];
      updateAvatarMutation({ variables: { image: file } });
    },
    [updateAvatarMutation]
  );

  const {
    handleSubmit,
    register,
    errors,
    reset,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (user)
      reset({ firstName: user.firstName ?? '', lastName: user.lastName ?? '' });
  }, [user, reset]);

  return (
    <Stack spacing={4} as={Card} boxShadow="sm">
      <Heading
        title="Account settings"
        subtitle="Update your personal information"
      />
      {isLoading ? (
        <Stack spacing={6}>
          <SkeletonCircle size={24 as any} />
          <SkeletonText noOfLines={2} spacing="4" skeletonHeight={4} />
        </Stack>
      ) : (
        <>
          <HStack spacing={6}>
            <UserAvatar size="xl" src={user?.avatar?.url} />
            <Stack spacing={4}>
              <Button
                variant="solid"
                onClick={() => inputFile.current?.click()}
              >
                Change picture
              </Button>
              <Button variant="link" onClick={() => deleteAvatarMutation()}>
                Delete picture
              </Button>
              <input
                id="avatarInput"
                type="file"
                accept="image/png,image/jpeg"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={onChangeAvatar}
              />
            </Stack>
          </HStack>
          <Stack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
            <Field label="First name" error={errors?.firstName?.message}>
              <TextInput
                placeholder="Tenzing"
                name="firstName"
                ref={register({ required: 'A first name is required' })}
              />
            </Field>
            <Field label="Last name" error={errors?.lastName?.message}>
              <TextInput
                placeholder="Norgay"
                name="lastName"
                ref={register({ required: 'A last name is required' })}
              />
            </Field>
            <Field label="Email">
              <TextInput
                placeholder="youremail@example.com"
                isDisabled
                value={user.email}
              />
            </Field>
            <Button
              isDisabled={!isDirty || !isValid}
              isLoading={loading}
              variant="subtle"
              colorScheme="red"
              type="submit"
            >
              Save changes
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const UpdatePassword: React.FC<unknown> = () => {
  const toast = useToast();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    await sendResetPasswordEmail();
    setSubmitting(false);
    toast({
      status: 'success',
      description: "We've sent a password reset link to your email!",
    });
  }, [toast]);

  return (
    <Stack as={Card} boxShadow="sm">
      <Heading
        title="Update your password"
        subtitle="Send a password reset link"
      />
      <Button
        variant="subtle"
        colorScheme="red"
        isLoading={submitting}
        onClick={onSubmit}
      >
        Send password reset link
      </Button>
    </Stack>
  );
};

export type UpdateEmailProps = {
  email?: string;
  isLoading?: boolean;
};

export const UpdateEmail: React.FC<UpdateEmailProps> = ({
  isLoading = true,
  email,
}) => {
  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, isValid, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange' });

  const [error, setError] = useState(null);

  // TODO, test this works
  const onSubmit = useCallback(
    async ({ email, confirmPassword }) => {
      await updateEmail(confirmPassword, email)
        .then(() => {
          toast({
            status: 'success',
            description: "We've sent a verification email to your new address!",
          });
          reset({ email: '' });
        })
        .catch(setError);
    },
    [toast, reset]
  );

  return (
    <Stack as={Card} boxShadow="sm">
      <Heading
        title="Update your email"
        subtitle="We'll send you an email to confirm your new address"
      />
      {isLoading ? (
        <Stack spacing={6}>
          <SkeletonText noOfLines={2} spacing="4" skeletonHeight={4} />
        </Stack>
      ) : (
        <Stack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <Field label="Current email">
            <TextInput isDisabled value={email} />
          </Field>
          <Field label="New email" error={error}>
            <TextInput
              placeholder="newemail@example.com"
              name="email"
              ref={register({
                required: 'An email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              })}
              onChange={(input) => {
                setValue('email', input?.target?.value?.trim());
              }}
            />
          </Field>
          <Field label="Confirm Password">
            <TextInput
              name="confirmPassword"
              type="password"
              ref={register({ required: true })}
              onChange={(input) => {
                setValue('confirmPassword', input.target.value);
              }}
            />
          </Field>
          <Button
            isLoading={isSubmitting}
            isDisabled={!isDirty || !isValid}
            variant="subtle"
            type="submit"
            colorScheme="red"
          >
            Confirm new email
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export const AccountSettings = () => {
  const { data, loading } = useQuery(ME_QUERY);

  return (
    <Stack spacing={6}>
      <Account isLoading={loading} user={data?.me} />
      <UpdatePassword />
      <UpdateEmail isLoading={loading} email={data?.me?.email} />
    </Stack>
  );
};
