import React from 'react';
import propTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';
import { Text } from '@outdoorly/text';
import { Divider } from '@outdoorly/divider';

export const Heading = ({ title, subtitle }) => {
  return (
    <Stack spacing={4} marginBottom="lg">
      <Stack spacing={3}>
        <Text as="h1" size="lg" fontWeight="medium">
          {title}
        </Text>
        {subtitle && <Text color="scheme.textSecondary">{subtitle}</Text>}
      </Stack>
      <Divider />
    </Stack>
  );
};

Heading.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
};
